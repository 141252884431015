import React from "react";
import { useRouter } from "next/router";
import { Subtitle, Paragraph } from "../../components/Templates/texts";
import { Container, Card, ImageContainer } from "./styles";

const Block02 = () => {
  const { locale = 'pt' } = useRouter();

  const mockBlock02Locale = {
      pt: {
        itens: [
          {
            width: 23,
            height: 32,
            icon: "/icon1.svg",
            subtitle: "Confiabilidade e Garantias",
            paragraph:
              "Banco de ativos digitais, custódia de ativos digitais, desenvolvimento de smart contracts e transações de cripto ativos.",
          },
          {
            width: 33,
            height: 25,
            icon: "/icon2.svg",
            subtitle: "Conversão de Ativo Real em Digital",
            paragraph:
              "Tokenizamos e fracionamos o seu ativo, deixando-o acessível para um grande número de investidores.",
          },
          {
            width: 30,
            height: 30,
            icon: "/icon3.svg",
            subtitle: "Liquidez e Comercialização",
            paragraph:
              "Liste seus tokens em uma plataforma rápida e segura e comercialize com investidores de todo o mundo.",
          },
        ],
      },
      en: {
        itens: [
          {
            width: 23,
            height: 32,
            icon: "/icon1.svg",
            subtitle: "Registration and verification",
            paragraph:
              "A quick and intuitive registration process to create an account and verify your identity, ensuring the security of transactions.",
          },
          {
            width: 33,
            height: 25,
            icon: "/icon2.svg",
            subtitle: "Security and reliability",
            paragraph:
              "Data encryption, two-factor authentication, suspicious activity monitoring and your digital assets under the military-grade custody security.",
          },
          {
            width: 30,
            height: 30,
            icon: "/icon3.svg",
            subtitle: "Customer Support",
            paragraph:
              "Solve any problem. The 2nd Market team is qualified and ready you more efficiently and quickly.",
          },
        ],
      },
    }

  const block02Locale = mockBlock02Locale[locale];

  return (
    <Container>
      {block02Locale.itens.map((item, index) => (
        <Card key={index}>
          <ImageContainer>
            <img
              loading="lazy"
              src={item.icon}
              width={item.width}
              alt={item.subtitle}
              height={item.height}
            />
          </ImageContainer>
          <Subtitle>{item.subtitle}</Subtitle>
          <Paragraph>{item.paragraph}</Paragraph>
        </Card>
      ))}
    </Container>
  );
};

export default Block02;
