import React from "react";
import { useRouter } from "next/router";
import { ButtonPattern } from "../../components";
import { Container, BoxText, ParagraphContent } from "./styles";

import {
  Title,
  Cartola,
  Paragraph,
  Additional,
} from "../../components/Templates/texts";
import ProductUrls from "@2ndmarket/components/src/helper/ProductUrls";

const Block01 = () => {
  const { locale = "pt" } = useRouter();

  const mockBlock01Locale = {
    pt: {
      cartola: "Soluções Financeiras",
      title:
        "Um ecossistema blockchain para sua melhor experiência com ativos digitais",
      paragraph:
        "Obtenha agora sua wallet gratuita para ativos digitais e criptomoedas. Negocie criptomoedas de forma ágil e segura. Crie ativos digitais de maneira fácil e rápida.",
      paragraphContent:
        "Conta digital, wallet, rentabilidade, tudo em um só lugar.",
      additional: "Crie sua conta gratuita em apenas 3 passos!",
      button: "Quero me cadastrar",
    },
    en: {
      cartola: "Financial Solutions",
      title:
        "The strategic ecosystem for your best experience with digital assets",
      paragraph:
        "Corporate or individual solutions for those who want to advance in the world of cryptocurrencies. Trade cryptocurrencies quickly and securely. Create tokens easily. Get paid in the world's most traded digital currencies.",
      paragraphContent:
        "Digital account, wallet, profitability, APIs, all in one place.",
      additional: "Create your free account in just 3 steps!",
      button: "Sing up",
    },
  };

  const block01Locale = mockBlock01Locale[locale];

  return (
    <Container id="quem-somos">
      <BoxText>
        <img
          alt=""
          width={9}
          height={9}
          src="/four-squares.svg"
          className="decoration one"
        />
        <Cartola>{block01Locale.cartola}</Cartola>
        <Title>{block01Locale.title}</Title>
        <Paragraph>{block01Locale.paragraph}</Paragraph>
        <ParagraphContent>
          <img loading="lazy" src="/icon.svg" width="40" height="40" alt="" />
          <Paragraph>{block01Locale.paragraphContent}</Paragraph>
        </ParagraphContent>
        <Additional>{block01Locale.additional}</Additional>
        <ButtonPattern anchor={ProductUrls.AUTH_REGISTER} newPage>
          {block01Locale.button}
        </ButtonPattern>
        <img
          alt=""
          width={3}
          height={3}
          src="/one-square.svg"
          className="decoration two"
        />
      </BoxText>
      <img
        alt=""
        width={600}
        height={600}
        loading="lazy"
        src="/img1.png"
        className="image"
      />
    </Container>
  );
};

export default Block01;
