import React from "react";
import { useRouter } from "next/router";
import { ButtonPattern } from "../../components";
import ProductUrls from "@2ndmarket/components/src/helper/ProductUrls";

import {
  Additional,
  Cartola,
  Paragraph,
  Title,
} from "../../components/Templates/texts";

import {
  BoxTitle,
  BoxText,
  Container,
  BoxContent,
  AdditionalContent,
  IconsContent,
} from "./styles";

const Block03 = () => {
  const { locale = "pt" } = useRouter();

  const mockBlock03Locale = {
    pt: {
      cartola: "Descentralização e autonomia",
      title:
        "A 2nd Market veio para mudar a forma como você interage com criptomoedas e blockchain",
    },
    en: {
      cartola: "Decentralization and autonomy",
      title:
        "2nd Market came to change the way you interact with cryptocurrencies and blockchain",
    },
  };

  const block03Locale = mockBlock03Locale[locale];

  const mockBlock03Content = {
    pt: {
      cartola: "Obtenha mais de seus ativos digitais",
      title: "O seu banco de ativos digitais",
      paragraph:
        "A Bank.ai é sua porta de entrada para digital finance. Obtenha agora sua wallet gratuita para ativos digitais e criptomoedas. Faça parte da nova economia!",
      paragraph2:
        "Traga cripto para o seu dia a dia. Com o Bank.ai Card você pode utilizar seus ativos digitais sem necessidade de conversão. Utilize seu cartão para realizar compras e saques em toda rede Mastercard no Brasil e no exterior.",
      additional: "Acesse nosso whitepaper",
      button: "Conheça a Bank.AI",
    },
    en: {
      cartola: "Get more from your digital assets",
      title: "Your digital asset bank. Use crypto in your everyday life.",
      paragraph:
        "The Bank.ai is your gateway to digital finance. Get your free wallet for digital assets and cryptocurrencies now. Be part of the new economy.",
      paragraph2:
        "Bring crypto into your everyday life. Pay in PIX, TED, transfer or tickets in cryptos. Use your card to make purchases and withdrawals throughout the Mastercard network in Brazil and abroad.",
      additional: "Download white paper",
      button: "Go to Bank.ai experience",
    },
  };

  const block03Content = mockBlock03Content[locale];

  return (
    <Container id="ativos-digitais">
      <BoxTitle>
        <div className="title">
          <Cartola>{block03Locale.cartola}</Cartola>
          <Title>{block03Locale.title}</Title>
        </div>
        <div className="decoration">
          <img
            alt=""
            width={3}
            height={3}
            className="two"
            src="/one-square.svg"
          />
          <img
            alt=""
            width={9}
            height={9}
            className="one"
            src="/four-squares.svg"
          />
        </div>
      </BoxTitle>
      <BoxContent>
        <img
          alt=""
          width={9}
          height={9}
          src="/four-squares.svg"
          className="one"
        />
        <BoxText right="8vw">
          <Cartola>{block03Content.cartola}</Cartola>
          <Title size="32px" weight="600" height="36px">
            {block03Content.title}
          </Title>
          <Paragraph>{block03Content.paragraph}</Paragraph>
          <Paragraph>{block03Content.paragraph2}</Paragraph>
          <IconsContent>
            <img
              alt=""
              width={40}
              height={43}
              loading="lazy"
              src="/cripto1-icon.svg"
            />
            <img
              alt=""
              width={40}
              height={43}
              loading="lazy"
              src="/cripto2-icon.svg"
            />
            <img
              alt=""
              width={40}
              height={43}
              loading="lazy"
              src="/cripto3-icon.svg"
            />
            <img
              alt=""
              width={40}
              height={43}
              loading="lazy"
              src="/cripto4-icon.svg"
            />
            <img
              alt=""
              width={40}
              height={43}
              loading="lazy"
              src="/cripto5-icon.svg"
            />
          </IconsContent>
          <AdditionalContent
            target="_blank"
            href={ProductUrls.BANKAI_WHITEPAPER}
          >
            <img
              alt=""
              width={20}
              height={20}
              loading="lazy"
              src="/download-icon.svg"
            />
            <Additional>{block03Content.additional}</Additional>
          </AdditionalContent>
          <ButtonPattern anchor={ProductUrls.BANKAI} newPage>
            {block03Content.button}
          </ButtonPattern>
        </BoxText>
        <img
          alt=""
          width={600}
          height={600}
          loading="lazy"
          className="box-image"
          src="/image-block4.jpg"
        />
      </BoxContent>
    </Container>
  );
};

export default Block03;
