import React from "react";
import { useRouter } from "next/router";
import ProductUrls from "@2ndmarket/components/src/helper/ProductUrls";
import {
  Title,
  Cartola,
  Paragraph,
  Additional,
} from "../../components/Templates/texts";

import { Container, BoxText, AdditionalContent } from "./styles";

const Block07 = () => {
  const { locale = "pt" } = useRouter();

  const mockBlock07Locale = {
    pt: {
      cartola: "Gratuito sem taxas",
      title:
        "Venha fazer parte do ecossistema 2nd Market e tenha o melhor das suas finanças.",
      paragraph:
        "Estamos trabalhando para aprimorar constantemente a segurança e adaptar nossos sistemas aos mais recentes requisitos regulamentares. Uma tecnologia segura e sem fronteiras para pagamentos com criptomoedas.",
      additional: "Baixar white paper",
    },
    en: {
      cartola: "Free and no fees",
      title:
        "Come be part of the 2nd Market ecosystem and have the best of your finances.",
      paragraph:
        "We are working to constantly improve security and adapt our systems to the latest regulatory requirements. A secure and borderless technology for cryptocurrency payments.",
      additional: "Download white paper",
    },
  };

  const block07Locale = mockBlock07Locale[locale];

  return (
    <Container id="resultados">
      <BoxText>
        <img
          alt=""
          width={9}
          height={9}
          className="one-decorator"
          src="/four-squares-w.svg"
        />
        <Cartola color="#FFFFFF">{block07Locale.cartola}</Cartola>
        <Title color="#FFFFFF">{block07Locale.title}</Title>
        <Paragraph color="#FFFFFF">{block07Locale.paragraph}</Paragraph>
        <AdditionalContent target="_blank" href={ProductUrls.BANKAI_WHITEPAPER}>
          <img
            alt=""
            width={20}
            height={20}
            loading="lazy"
            src="/download-icon-w.svg"
          />
          <Additional color="#FFFFFF">{block07Locale.additional}</Additional>
        </AdditionalContent>
      </BoxText>
      <img
        loading="lazy"
        src="/image-block7.jpg"
        alt=""
        width={600}
        height={600}
        className="box-image"
      />
    </Container>
  );
};

export default Block07;
