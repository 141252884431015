import styled from "styled-components";

export const Container = styled.div`
  gap: 8vw;
  display: flex;
  padding: 85px 6vw;
  align-items: center;
  background-color: #4150e6;
  justify-content: space-between;

  img.image {
    width: 40%;
    height: auto;
  }

  @media only screen and (max-width: 1200px) {
    flex-direction: column;
    padding: 47px 4vw;

    .box-image {
      width: 100%;
      height: 100%;
    }
  }
`;

export const BoxText = styled.div`
  max-width: 38vw;
  display: flex;
  flex-direction: column;

  .one-decorator {
    align-self: flex-end;
    margin: 0 0 10px 0;

    position: relative;
    top: -120px;
    right: -90px;
  }

  h1 {
    margin-bottom: 13px;

    @media only screen and (max-width: 1200px) {
      font-size: 30px;
      line-height: 36px;
    }
  }

  @media only screen and (max-width: 1200px) {
    max-width: 100%;
    margin-right: 0;

    .one-decorator {
      display: none;
    }
  }
`;

export const BoxImage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  padding: 80px 112px 62px 108px;
  border-radius: 2px;

  h1 {
    font-weight: bold;
    font-size: 42px;
    line-height: 50px;
    color: #4150e6;
    margin: 17px 0 37px 0;
  }

  p {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #4150e6;
    margin: 0;
  }

  span {
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #8c96a0;
  }

  @media only screen and (max-width: 1200px) {
    padding: 40px 31px 26px 31px;
  }
`;

export const AdditionalContent = styled.a`
  display: flex;
  align-items: center;
  margin-top: 40px;

  a {
    display: flex;
    align-items: center;

    &:hover {
      text-decoration: underline;
    }
  }

  p {
    margin: 0;
  }

  img {
    margin-right: 13px;
  }
`;
