import styled from "styled-components";

export const Container = styled.div`
  gap: 8vw;
  display: flex;
  align-items: center;
  margin: 138px 6vw 0 6vw;
  justify-content: space-between;

  img.image {
    width: 40%;
    height: auto;
  }

  @media only screen and (max-width: 1200px) {
    flex-direction: column;
    margin: 100px 4vw 0 4vw;

    img.image {
      width: 100%;
      height: 100%;
    }
  }
`;

export const BoxText = styled.div`
  display: flex;
  max-width: 32vw;
  flex-direction: column;

  h1 {
    margin-bottom: 16px;
  }

  p {
    margin-bottom: 27px;
  }

  @media only screen and (max-width: 1200px) {
    max-width: 100%;
    margin-right: 0;

    h1 {
      font-size: 30px;
      font-weight: bold;
      line-height: 36px;
    }

    button {
      width: 230px;
      margin-bottom: 50px;
    }

    .decoration {
      display: none;
    }
  }

  .decoration {
    &.one {
      position: absolute;
      left: 44vw;
    }
    &.two {
      left: 30vw;

      @media only screen and (max-width: 1440px) {
        bottom: 0;
        position: relative;
      }

      @media only screen and (min-width: 1900px) {
        bottom: -3vw;
        position: relative;
      }
    }
  }
`;

export const ParagraphContent = styled.div`
  display: flex;
  align-items: center;

  img {
    margin-right: 17px;
  }

  p {
    margin: 0;
  }
`;
