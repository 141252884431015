import { useState, useEffect } from 'react'

export default function Loading(props) {

    const [progress, setProgress] = useState(0)

    useEffect(() => {

        if(props.isLoading) {

            setProgress(0)
            const intervalId = setInterval(function() {
                setProgress(progress => progress + 0.1)
            }, 10)

            return function() {

                clearInterval(intervalId)
                setProgress(0)
            }
        }

    }, [props.isLoading])

    return <div className="loading">
        <div className="loading-message">
            <div className="progress-bar">
                <div className="progress" style={{ width: progress+'%' }}></div>
            </div>
            <p>{ props.message ? props.message : '' }</p>
        </div>
        <style>{`
            .loading {

                position: absolute;
                display: ${ props.isLoading ? 'flex' : 'none' };

                top: 0px;
                left: 0px;
                width: 100%;
                height: 100%;
                background-color: rgba(255, 255, 255, 0.75);

                justify-content: center;
                align-items: center;
            }
            .loading-message {

                display: flex;
                flex-direction: column;
                width: 400px;
                max-width: 100%;
                height: 100px;
                margin: 0 auto;
                text-align: center;
            }
            .progress-bar {

                width: 100%;
                height: 5px;
                background-color: #F2F2FE;
                overflow: hidden;
            }
            .progress {

                width: 0%;
                height: 5px;
                background-color: #4150e6;
            }
            .loading-message p {

                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                color: #8C96A0;
                margin-top: 20px;
            }
        `}</style>
    </div>
}
Loading.defaultProps = {
    isLoading: false
}