import React from "react";
import { useRouter } from "next/router";
import ProductUrls from "@2ndmarket/components/src/helper/ProductUrls";

import { ButtonPattern } from "../../components";

import {
  Additional,
  Cartola,
  Paragraph,
  Title,
} from "../../components/Templates/texts";

import {
  BoxTitle,
  BoxText,
  Container,
  BoxContent,
  AdditionalContent,
} from "./styles";

const Block05 = () => {
  const { locale = "pt" } = useRouter();

  const mockBlock05Locale = {
    pt: {
      cartola: "Soluções Financeiras",
      title:
        "Alcance novas alternativas de investimentos em ativos digitais de uma forma totalmente segura",
    },
    en: {
      cartola: "Financial Solutions",
      title:
        "Reach new investment alternatives of digital assets in a fully safe way",
    },
  };

  const block05Locale = mockBlock05Locale[locale];

  const mockBlock05Content01 = {
    pt: {
      cartola: "Proteja seu dinheiro",
      title: "Armazenamento de criptoativos de maneira eficiente e segura.",
      paragraph: (
        <>
          Interaja com criptoativos acessando serviços de
          <span> custódia, seguro e staking de ativos digitais</span> por meio
          de uma tecnologia segura e acessível. Ideal para investidores
          institucionais e provedores de serviços.
        </>
      ),
      paragraph2:
        "Armazene ativos digitais em um ambiente com várias camadas de segurança que elimina os vetores tradicionais de ataques na blockchain, ao mesmo tempo em que oferece acessibilidade e agilidade para utilização.",
      additional:
        "Trazemos soluções para quem deseja imergir no universo de criptoativos.",
      button: "Conheça a Cust.on",
    },
    en: {
      cartola: "Protect your money",
      title: "Efficient and secure storage of crypto assets.",
      paragraph:
        "Explore crypto assets by accessing custody, insurance, and staking services for digital assets through a secure and affordable technology. Ideal for institutional investors and service providers.",
      paragraph2:
        "Store digital assets in a multi-layered security environment that removes traditional attack vectors on the blockchain, while providing accessibility and agility for use.",
      additional:
        "We bring solutions from the world of crypto assets universe.",
      button: "Go to Cust.on experience",
    },
  };

  const block05Content01 = mockBlock05Content01[locale];

  const mockBlock05Content02 = {
    pt: {
      cartola: "Negociação rápida e segura",
      title: "Compre, venda e troque criptografia facilmente",
      paragraph:
        "Liquidez e rentabilidade ao seu alcance. Controle seus ativos e criptomoedas, utilizando um ambiente de fácil interação.",
      additional: [
        {
          text: "Conta que combina finanças tradicionais e ativos digitais",
        },
        {
          text: "Efetue pagamentos e recebimentos diretamente em criptomoedas",
        },
        {
          text: "Negociações automatizadas",
        },
        {
          text: "Tão seguro quanto os bancos tradicionais",
        },
        {
          text: "Transações sem fronteiras",
        },
      ],
      button: "Conheça a Bra.EX",
    },
    en: {
      cartola: "Fast and safe trading",
      title: "Buy, sell and trade digital assets easily.",
      paragraph:
        "Liquidity and profitability at your reach. Control your assets and cryptocurrencies using an user-friendly interface.",
      additional: [
        {
          text: "Automated trading",
        },
        {
          text: "Wallet that combines traditional finance and assets",
        },
        {
          text: "Multiple pairs to trade",
        },
        {
          text: "Safer than traditional banks",
        },
        {
          text: "Borderless transactions",
        },
      ],
      button: "Go to Bra.ex experience",
    },
  };

  const block05Content02 = mockBlock05Content02[locale];

  return (
    <Container id="ativos-digitais">
      <BoxTitle>
        <div className="title">
          <Cartola>{block05Locale.cartola}</Cartola>
          <Title>{block05Locale.title}</Title>
        </div>
        <div className="decoration">
          <img
            alt=""
            width={3}
            height={3}
            className="two"
            src="/one-square.svg"
          />
          <img
            alt=""
            width={9}
            height={9}
            className="one"
            src="/four-squares.svg"
          />
        </div>
      </BoxTitle>

      <BoxContent top="85px" mobile="column">
        <img
          alt=""
          loading="lazy"
          className="box-image"
          src="/image1-block9.png"
          width={600}
          height={600}
        />
        <BoxText left="1vw">
          <Cartola>{block05Content01.cartola}</Cartola>
          <Title size="32px" weight="600" height="36px">
            {block05Content01.title}
          </Title>
          <Paragraph>{block05Content01.paragraph}</Paragraph>
          <Paragraph>{block05Content01.paragraph2}</Paragraph>
          <AdditionalContent>
            <Additional>{block05Content01.additional}</Additional>
          </AdditionalContent>
          <ButtonPattern anchor={ProductUrls.CUSTON} newPage>
            {block05Content01.button}
          </ButtonPattern>
        </BoxText>
      </BoxContent>

      <BoxContent>
        <img
          alt=""
          width={9}
          height={9}
          className="one"
          src="/four-squares.svg"
        />
        <BoxText className="sub-box">
          <Cartola>{block05Content02.cartola}</Cartola>
          <Title size="32px" weight="600" height="36px">
            {block05Content02.title}
          </Title>
          <Paragraph>{block05Content02.paragraph}</Paragraph>
          {block05Content02.additional.map((item, index) => (
            <AdditionalContent key={index}>
              <img
                alt=""
                width="14"
                height="14"
                loading="lazy"
                src="/check-icon.svg"
              />
              <Additional>{item.text}</Additional>
            </AdditionalContent>
          ))}
          <ButtonPattern anchor={ProductUrls.BRAEX} newPage>
            {block05Content02.button}
          </ButtonPattern>
        </BoxText>
        <img
          alt=""
          loading="lazy"
          className="box-image"
          src="/image1-block3.jpg"
          width={600}
          height={600}
        />
      </BoxContent>
    </Container>
  );
};

export default Block05;
