import React from "react";
import { useRouter } from "next/router";
import ProductUrls from "@2ndmarket/components/src/helper/ProductUrls";

import { ButtonPattern } from "../../components";

import {
  Additional,
  Cartola,
  Paragraph,
  Title,
} from "../../components/Templates/texts";

import {
  BoxTitle,
  BoxText,
  Container,
  BoxContent,
  AdditionalContent,
} from "./styles";

const Block09 = () => {
  const { locale = "pt" } = useRouter();

  const mockBlock09Locale = {
    pt: {
      cartola: "Custódia de alto padrão",
      title:
        "Oferecemos mais tranquilidade para a sua rotina: ativos digitais seguros desde o primeiro dia.",
    },
    en: {
      cartola: "A new way of living",
      title:
        "We offer more tranquility for your routine: secure digital assets from day one.",
    },
  };

  const block09Locale = mockBlock09Locale[locale];

  const block09Content = {
    pt: {
      cartola: "Blockchain + IA",
      title: "A Bkai Chain está pronta para revolucionar o uso da blockchain.",
      paragraph:
        "Bkai Chain é uma rede que permite as empresas aproveitarem o poder da tecnologia Web3 por meio da inteligência artificial que abrange ferramentas Web3 para permitir um acesso fácil à blockchain.",
      additionals: [
        {
          addicional: "Plataforma de desenvolvimento sem código",
        },
        {
          addicional: "Protocolo de consenso Proof-of-Stake (PoS)",
        },
        {
          addicional: "Smart contract libraries",
        },
        {
          addicional: "Code to earn",
        },
        {
          addicional: "Cartão Virtual Web3",
        },
      ],
      paragraph2:
        "Utilize para liquidação financeira em qualquer transação e para qualquer tipo de ação e validação.",
      button: "Experimente",
    },
    en: {
      cartola: "Blockchain + IA",
      title: "The Bkai Chain is ready to disrupt the use of blockchain.",
      paragraph:
        "BKAI Chain is a network that allows companies to take advantage of the power of Web3 technologies through artificial intelligence that embraces Web3 tools to enable easy access to blockchain.",
      additionals: [
        {
          addicional: "No-code development platform",
        },
        {
          addicional: "Proof-of-Stake (PoS) consensus protocol",
        },
        {
          addicional: "Smart contract libraries",
        },
        {
          addicional: "Code to earn",
        },
        {
          addicional: "Web3 Virtual Card",
        },
      ],
      paragraph2:
        "Use for financial settlement (any transaction) and for any kind of action and permission (validation).",
      button: "Go to Bank.ai experience",
    },
  };

  const block09ContentLocale = block09Content[locale];

  return (
    <Container id="ativos-digitais">
      <BoxTitle>
        <div className="title">
          <Cartola>{block09Locale.cartola}</Cartola>
          <Title>{block09Locale.title}</Title>
        </div>
        <div className="decoration">
          <img
            alt=""
            width={9}
            height={9}
            className="one"
            src="/four-squares.svg"
          />
          <img
            alt=""
            width={135}
            height={100}
            className="three"
            src="/broken-square.svg"
          />
        </div>
      </BoxTitle>

      <BoxContent>
        <BoxText right="8vw">
          <Cartola>{block09ContentLocale.cartola}</Cartola>
          <Title size="32px" weight="600" height="36px">
            {block09ContentLocale.title}
          </Title>
          <Paragraph>{block09ContentLocale.paragraph}</Paragraph>
          {block09ContentLocale.additionals.map((item, index) => (
            <AdditionalContent key={index}>
              <img
                alt=""
                width="14"
                height="14"
                loading="lazy"
                src="/check-icon.svg"
              />
              <Additional>{item.addicional}</Additional>
            </AdditionalContent>
          ))}
          <Paragraph>{block09ContentLocale.paragraph2}</Paragraph>
          <ButtonPattern anchor={ProductUrls.BRAEX} newPage>
            {block09ContentLocale.button}
          </ButtonPattern>
        </BoxText>
        <img
          src="/Image-IA.png"
          className="box-image"
          loading="lazy"
          alt=""
          width={600}
          height={600}
        />
      </BoxContent>
    </Container>
  );
};

export default Block09;
