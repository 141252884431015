import styled from "styled-components";

export const Cartola = styled.h2`
  color: ${(props) => (props.color ? props.color : "#313EE2")};
  font-weight: ${(props) => (props.weight ? props.weight : "500")};
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 8px;
`;

export const Title = styled.h1`
  font-size: ${(props) => (props.size ? props.size : "42px")};
  font-weight: ${(props) => (props.weight ? props.weight : "700")};
  line-height: ${(props) => (props.height ? props.height : "50px")};
  color: ${(props) => (props.color ? props.color : "#292D37")};
  margin: 0;
`;

export const Subtitle = styled.h2`
  font-size: ${(props) => (props.size ? props.size : "16px")};
  font-weight: ${(props) => (props.weight ? props.weight : "600")};
  line-height: ${(props) => (props.height ? props.height : "20px")};
  color: #292d37;
  margin-top: 0;
  margin-bottom: 8px;
`;

export const Paragraph = styled.p`
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  font-style: normal;
  color: ${(props) => (props.color ? props.color : "#535668")};
  margin: 0;
`;

export const Additional = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => (props.color ? props.color : "#5a6473")};
  margin: 30px 0;
`;

export const Strong = styled.strong`
  font-weight: 600;
  color: ${(props) => (props.white ? "white" : "#8C96A0")};

  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : "0px")};
`;
