import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 50px 0;
  padding: 100px 0;
  background-image: url("404-background.svg");
  background-position: center top;
  background-repeat: no-repeat;

  @media only screen and (max-width: 1024px) {
    margin: 0;
    padding: 50px 0 100px;
  }
`;

export const BoxContent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  h1 {
    margin-top: 50px;
    margin-bottom: 15px;
    font-weight: 600;
    font-size: 32px;
    line-height: 36px;
    color: #5a6473;
  }
  p {
    font-size: 16px;
    line-height: 22px;
    color: #8c96a0;
    margin: 0;
    width: 40%;
  }

  a {
    margin-top: 28px;
    width: 229px;
  }

  @media only screen and (max-width: 1024px) {
    p {
      padding: 0 5vw;
      width: auto;
    }

    h1 {
      margin-top: 0;
    }
  }
`;
