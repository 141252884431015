import styled from "styled-components";

export const Container = styled.div`
  margin: 100px 12vw;
  display: flex;

  .one-footer {
    position: absolute;
    left: 0;

    margin: -100px 0 0 100px;
  }

  .marker-logo {
    position: absolute;
    left: 0;

    margin: 280px 0 0 2px;
  }

  @media only screen and (max-width: 1200px) {
    flex-direction: column;
    margin: 60px 4vw 50px 4vw;

    .one-footer,
    .marker-logo {
      display: none;
    }
  }
`;

export const SubContainer = styled.div`
  gap: 6vw;
  display: flex;
  align-items: center;
  justify-content: space-between;

  img.image {
    width: 50%;
    height: auto;
  }

  @media only screen and (max-width: 1200px) {
    flex-direction: column;

    .box-image {
      width: 100%;
      height: 100%;
    }
  }
`;

export const BoxText = styled.div`
  max-width: 32vw;

  h1 {
    margin-bottom: 21px;
  }

  p {
    margin-bottom: 11px;
  }

  a {
    margin-top: 25px;
  }

  .two {
    margin: 100px 0 0 350px;
  }

  @media only screen and (max-width: 1200px) {
    max-width: 100%;
    margin: 0 2vw 24px 2vw;

    h1 {
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
    }

    .two {
      display: none;
    }
  }
`;
