import React from "react";
import { useRouter } from "next/router";
import ProductUrls from "@2ndmarket/components/src/helper/ProductUrls";

import {
  Additional,
  Paragraph,
  Cartola,
  Title,
} from "../../components/Templates/texts";

import { ButtonPattern } from "../../components";

import { Container, SubContainer, BoxText } from "./styles";

const Block06 = () => {
  const { locale = "pt" } = useRouter();

  const mockBlock06Locale = {
    pt: {
      cartola: "Tokens digitais não fungíveis",
      title: "Descubra, colete e venda NFT exclusivos.",
      paragraph:
        "Criamos seu projeto com desenvolvimento de smart contracts, emissão, ambiente de negociação, comunidade, distribuição e linha de suporte.",
      paragraph2:
        "Acesse o mundo cripto para você, com investimentos e NFTs, ou para sua empresa, software e dApps.",
      additicional: "Token.aim desenvolve todo seu projeto.",
      buttom: "Conheça a Token.aim",
    },
    en: {
      cartola: "Software and NFTs",
      title: "Discover, collect, create and trade unique NFTs.",
      paragraph:
        "We create your project from the ground up with smart contract development, issuance, trading interface, community, distribution and support line.",
      paragraph2:
        "Access the crypto world for yourself, with investments and NFTs, or for your company, software and dApps.",
      additicional: "Token.aim develops your entire project.",
      buttom: "Go to Token.aim experience",
    },
  };

  const block06Locale = mockBlock06Locale[locale];

  return (
    <Container id="tokens">
      <img
        alt=""
        width={9}
        height={9}
        className="one-footer"
        src="/four-squares.svg"
      />
      <img
        alt=""
        width={115}
        height={236}
        className="marker-logo"
        src="/marker.png"
      />
      <SubContainer>
        <img
          alt=""
          loading="lazy"
          className="box-image"
          src="/image-block6.jpg"
          width={600}
          height={600}
        />
        <BoxText>
          <Cartola>{block06Locale.cartola}</Cartola>
          <Title weight="600" size="32px" height="36px">
            {block06Locale.title}
          </Title>
          <Paragraph>{block06Locale.paragraph}</Paragraph>
          <Paragraph>{block06Locale.paragraph2}</Paragraph>
          <Additional>{block06Locale.additicional}</Additional>
          <ButtonPattern anchor={ProductUrls.TOKENAIM} newPage>
            {block06Locale.buttom}
          </ButtonPattern>
          <img
            alt=""
            width={3}
            height={3}
            className="two"
            src="/one-square.svg"
          />
        </BoxText>
      </SubContainer>
    </Container>
  );
};

export default Block06;
