import React from "react";
import { useRouter } from "next/router";
import ProductUrls from "@2ndmarket/components/src/helper/ProductUrls";
import { ButtonPattern } from "../../components";

import {
  Additional,
  Cartola,
  Paragraph,
  Title,
} from "../../components/Templates/texts";

import {
  BoxText,
  Container,
  BoxContent,
  AdditionalContent,
  BoxTitle,
} from "./styles";

const Block04 = () => {
  const { locale = "pt" } = useRouter();

  const mockBlock04Locale = {
    pt: {
      cartola: "Soluções Financeiras",
      title:
        "Novas alternativas de investimentos digitais financeiros de uma forma totalmente segura",
    },
    en: {
      cartola: "High standard custody",
      title:
        "We offer more tranquility for your routine: Digital assets safe from day one",
    },
  };

  const block04Locale = mockBlock04Locale[locale];

  const mockBlock04Content = {
    pt: {
      cartola: "Tokenização de ativos",
      title: "Transforme ativos reais ilíquidos em ativos digitais líquidos",
      paragraph:
        "Do mundo real para o digital - liquidez para quem possui ativos ilíquidos. Do ativo único ao fracionado. De um só investidor para uma exchange.",
      paragraph2:
        "Tokenização é o processo de escrituração de ativos em uma blockchain, trazendo para o mercado oportunidades para investidores e empresas no mercado.",
      additional: "Transparência, autonomia e liquidez.",
      button: "Conheça a Token.aim",
    },
    en: {
      cartola: "Asset tokenization",
      title: "Transform illiquid real assets into liquid digital assets",
      paragraph:
        "From the real world to the digital - liquidity for those with illiquid assets. From single to fractional assets. From a single investor to an exchange.",
      paragraph2:
        "Tokenization is the process of bookkeeping assets on a blockchain, bringing opportunities to the market for investors and companies in the market.",
      additional: "Transparency, autonomy and liquidity.",
      button: "Go to Token.aim experience",
    },
  };

  const block04Content = mockBlock04Content[locale];

  return (
    <Container id="produtos">
      <BoxTitle>
        <div className="title">
          <Cartola>{block04Locale.cartola}</Cartola>
          <Title>{block04Locale.title}</Title>
        </div>
        <div className="decoration">
          <img
            alt=""
            width={9}
            height={9}
            className="one"
            src="/four-squares.svg"
          />
          <img
            alt=""
            width={3}
            height={3}
            className="two"
            src="/one-square.svg"
          />
        </div>
      </BoxTitle>
      <BoxContent>
        <img
          alt=""
          width={9}
          height={9}
          className="one"
          src="/four-squares.svg"
        />
        <img
          alt=""
          loading="lazy"
          className="box-image"
          src="/image2-block3.jpg"
          width={600}
          height={600}
        />
        <BoxText>
          <Cartola>{block04Content.cartola}</Cartola>
          <Title size="32px" weight="600" height="36px">
            {block04Content.title}
          </Title>
          <Paragraph>{block04Content.paragraph}</Paragraph>
          <Paragraph>{block04Content.paragraph2}</Paragraph>
          <AdditionalContent>
            <img
              src="/check-icon.svg"
              loading="lazy"
              height="14"
              width="14"
              alt=""
            />
            <Additional>{block04Content.additional}</Additional>
          </AdditionalContent>
          <ButtonPattern anchor={ProductUrls.TOKENAIM} newPage>
            {block04Content.button}
          </ButtonPattern>
        </BoxText>
        <span className="one-footer">&nbsp;</span>
      </BoxContent>
    </Container>
  );
};

export default Block04;
