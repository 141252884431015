import styled from "styled-components";

export const Container = styled.div`
  margin: 0 6vw;
  margin-top: 150px;

  @media only screen and (max-width: 1200px) {
    margin: 50px 4vw;
  }
`;

export const BoxTitle = styled.div`
  margin-bottom: 160px;

  gap: 15vw;
  display: flex;
  flex-direction: row;

  .title {
    max-width: 50vw;
  }

  .decoration {
    max-width: 30vw;

    gap: 19vw;
    display: flex;
    flex-direction: row;

    .one {
      margin-top: 5vw;
    }

    .three {
      width: 156px;
      height: 206px;

      right: 0;
      position: absolute;
    }
  }

  @media only screen and (max-width: 1200px) {
    max-width: 100%;
    margin-bottom: 53px;

    h1 {
      font-weight: bold;
      font-size: 30px;
      line-height: 36px;
    }

    .title {
      max-width: 100%;
    }

    .decoration {
      display: none;
    }
  }
`;

export const BoxContent = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.direction ? props.direction : "row")};
  justify-content: space-between;
  align-items: center;
  margin: 0 6vw;
  margin-top: ${(props) => (props.top ? props.top : "0")};
  margin-bottom: 150px;

  img.box-image {
    width: 50%;
    height: auto;
  }

  @media only screen and (max-width: 1200px) {
    flex-direction: ${(props) =>
      props.mobile ? props.mobile : "column-reverse"};
    margin: 0;
    margin-bottom: 61px;

    img.box-image {
      width: 100%;
      height: 100%;
    }
  }
`;

export const BoxText = styled.div`
  max-width: 32vw;
  margin-right: ${(props) => (props.right ? props.right : "0")};
  margin-left: ${(props) => (props.left ? props.left : "0")};

  @media only screen and (max-width: 1200px) {
    margin: 0;
  }

  h1 {
    margin-bottom: 13px;
  }

  p {
    margin-bottom: 12px;

    span {
      color: #4150e6;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
    }
  }

  a {
    margin-top: 25px;
  }

  @media only screen and (max-width: 1200px) {
    max-width: 100%;
    margin-top: 25px;

    h1 {
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
    }

    a {
      width: 230px;
    }
  }
`;

export const AdditionalContent = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 11px;

  p {
    margin: 0;
  }

  img {
    margin-right: 13px;
  }
`;

export const IconsContent = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 25vw;
  margin: 18px 0;

  @media only screen and (max-width: 1200px) {
    max-width: 95%;
  }
`;
