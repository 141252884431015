import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 138px 6vw;

  @media only screen and (max-width: 1200px) {
    flex-direction: column;
    margin: 50px 4vw 0 4vw;
  }
`;

export const Card = styled.div`
  background-color: rgba(255, 255, 255, 1);
  max-width: 28vw;
  padding: 34px 29px 24px 29px;
  border-radius: 2px;
  box-shadow: 0 2px 6px 0 rgba(26, 36, 137, 0.05);

  @media only screen and (max-width: 1200px) {
    max-width: 100%;
    margin-bottom: 20px;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f9;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  margin-bottom: 24px;
`;
