import styled from "styled-components";

export const Container = styled.div`
  gap: 8vw;
  display: flex;
  flex-direction: column;

  margin: 0 6vw;

  @media only screen and (max-width: 1200px) {
    margin: 50px 4vw;

    .box-image {
      width: 100%;
      height: 100%;
    }
  }
`;

export const BoxTitle = styled.div`
  gap: 15vw;
  display: flex;
  flex-direction: row;

  .title {
    max-width: 50vw;
  }

  .decoration {
    max-width: 30vw;

    gap: 19vw;
    display: flex;
    flex-direction: row;

    .two {
      margin-top: 10vw;
    }
  }

  @media only screen and (max-width: 1200px) {
    max-width: 100%;
    margin-bottom: 53px;

    h1 {
      font-weight: bold;
      font-size: 30px;
      line-height: 36px;
    }

    .title {
      max-width: 100%;
    }

    .decoration {
      display: none;
    }
  }
`;

export const BoxContent = styled.div`
  gap: 5vw;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: ${(props) => (props.top ? props.top : "0")};
  flex-direction: ${(props) => (props.direction ? props.direction : "row")};

  .one {
    margin-top: 5vw;
  }

  @media only screen and (max-width: 1200px) {
    flex-direction: ${(props) =>
      props.mobile ? props.mobile : "column-reverse"};
    margin: 0;

    .one {
      display: none;
    }
  }
`;

export const BoxText = styled.div`
  max-width: 32vw;
  margin-right: ${(props) => (props.right ? props.right : "0")};
  margin-left: ${(props) => (props.left ? props.left : "0")};

  @media only screen and (max-width: 1200px) {
    margin: 0;
  }

  h1 {
    margin-bottom: 13px;
  }

  p {
    margin-bottom: 12px;

    span {
      color: #ff465a;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
    }
  }

  a {
    margin-top: 25px;
  }

  @media only screen and (max-width: 1200px) {
    max-width: 100%;

    h1 {
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
    }

    a {
      width: 230px;
    }
  }
`;

export const AdditionalContent = styled.a`
  display: flex;
  align-items: center;

  a {
    display: flex;
    align-items: center;

    &:hover {
      text-decoration: underline;
    }
  }

  p {
    margin: 0;
  }

  img {
    margin-right: 13px;
  }
`;

export const IconsContent = styled.div`
  display: flex;
  gap: 10px;
  margin: 18px 0;
`;
