import styled from "styled-components";

export const Container = styled.div`
  gap: 150px;
  display: flex;
  flex-direction: column;
  padding-top: 150px;

  margin: 0 6vw;

  @media only screen and (max-width: 1200px) {
    margin: 0 4vw;
  }
`;

export const BoxTitle = styled.div`
  gap: 15vw;
  display: flex;
  flex-direction: row;

  .title {
    max-width: 56vw;
  }

  .decoration {
    max-width: 30vw;

    gap: 10vw;
    display: flex;
    flex-direction: row;

    .one {
      margin-top: 5vw;
    }

    .two {
      margin-top: 10vw;
    }
  }

  @media only screen and (max-width: 1200px) {
    margin: 0;
    max-width: 100%;

    h1 {
      font-weight: bold;
      font-size: 30px;
      line-height: 36px;
    }

    .title {
      max-width: 100%;
    }

    .decoration {
      display: none;
    }
  }
`;

export const BoxContent = styled.div`
  gap: 6vw;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;

  .one-footer {
    margin-top: 5vw;
  }

  .box-image {
    width: 50%;
    height: auto;
  }

  @media only screen and (max-width: 1200px) {
    flex-direction: column;
    margin: 50px 0 0 0;

    .box-image {
      width: 100%;
      height: 100%;
    }

    .one-footer,
    .one {
      display: none;
    }
  }
`;

export const BoxText = styled.div`
  h1 {
    margin-bottom: 13px;
  }

  p {
    margin-bottom: 12px;
  }

  a {
    margin-top: 25px;
  }

  @media only screen and (max-width: 1200px) {
    max-width: 100%;

    h1 {
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
    }
  }
`;

export const AdditionalContent = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 11px;

  p {
    margin: 0;
  }

  img {
    margin-right: 13px;
  }
`;
