import styled from "styled-components";
const arrow = "/select-arrow.png";

export const Container = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  padding: 85px 6vw;
  background-color: #f0f0f9;
  align-items: center;

  @media only screen and (max-width: 1200px) {
    flex-direction: column;
    padding: 45px 4vw 56px 4vw;
  }
`;
export const BoxText = styled.div`
  max-width: 34vw;

  @media only screen and (max-width: 1200px) {
    max-width: 100%;

    h1 {
      font-size: 30px;
      line-height: 36px;
    }
  }
`;

export const BoxAdditional = styled.div`
  display: flex;
  align-items: center;

  p {
    margin-left: 11px;
  }
`;

export const BoxForm = styled.form`
  min-width: 38vw;

  @media only screen and (max-width: 1200px) {
    width: 100%;
  }

  select {
    background-color: #ffffff;
    border: 1px solid #f0f0f9;
    padding: 19.5px 15px;
    font-size: 16px;
    border-radius: 2px;
    color: #8c96a0;
    width: 50%;
    height: 56px;
    margin-left: 9px;
    font-family: Montserrat;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url(${arrow});
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: calc(100% - 20px);

    &:focus {
      border-color: #4150e6;
      outline: none;
    }

    @media only screen and (max-width: 1200px) {
      width: 100%;
      margin-bottom: 10px;
      margin-left: 0;
    }
  }

  textarea {
    border: 1px solid #f0f0f9;
    border-radius: 2px;
    padding: 19.5px 15px;
    width: 100%;
    font-size: 16px;
    color: #8c96a0;
    resize: none;
    height: 125px;
    font-family: Montserrat;

    &:focus {
      border-color: #4150e6;
      outline: none;
    }
  }

  input[type="submit"] {
    width: 172px;
    margin-top: 30px;

    @media only screen and (max-width: 1200px) {
      width: 100%;
      margin-top: 20px;
    }
  }

  .button {
    display: flex;
    justify-content: flex-end;
  }
`;

export const BoxSelect = styled.div`
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 1200px) {
    flex-direction: column;
  }
`;
