import { useState } from "react";
import { useRouter } from "next/router";

import {
  Title,
  Cartola,
  Additional,
  Paragraph,
  Strong,
} from "../../components/Templates/texts";
import { ButtonPattern, BaseInput, PhoneInput } from "../../components";
import Loading from "../../components/Loading";
import { Check } from "../../components/icons";

import { Api } from "../../api/api";

import {
  Container,
  BoxText,
  BoxAdditional,
  BoxForm,
  BoxSelect,
} from "./styles";

const Block08 = () => {
  const { locale = "pt" } = useRouter();

  const [isLoading, setIsLoading] = useState(false);
  const [isMessageSent, setIsMessageSent] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const submit = function (event) {
    event.preventDefault();

    setIsLoading(true);
    setIsMessageSent(false);

    Api.submitContact({
      name: name,
      email: email,
      phone: phone,
      subject: event.target.querySelector('[name="subject"]').value,
      message: message,
    }).then(function (response) {
      setIsLoading(false);
      setIsMessageSent(true);
    });

    return false;
  };

  const mockBlock08Locale = {
    pt: {
      cartola: "Cadastre-se",
      title: "Oferecemos a melhor experiência digital em criptofinance",
    },
    en: {
      cartola: "Sign up",
      title: "We offer the best digital experience in cryptofinance",
    },
  };

  const block08Locale = mockBlock08Locale[locale];

  const mockFormLocale = {
    pt: {
      name: "Nome",
      email: "E-mail",
      phone: "Telefone",
      subject: "Assunto",
      message: "Mensagem",
      button: "Enviar",
      validation: "Mensagem enviada!",
      options: [
        { value: "cadastro", label: "Pré-cadastro" },
        { value: "duvidas", label: "Dúvidas / Suporte" },
      ],
    },
    en: {
      name: "Name",
      email: "E-mail",
      phone: "Phone",
      subject: "Subject",
      message: "Message",
      button: "Send",
      validation: "Message sent!",
      options: [
        { value: "cadastro", label: "Sign up" },
        { value: "duvidas", label: "Doubts / Support" },
      ],
    },
  };

  const formLocale = mockFormLocale[locale];

  return (
    <Container>
      <BoxText>
        <Cartola>{block08Locale.cartola}</Cartola>
        <Title id="contato">{block08Locale.title}</Title>
        <BoxAdditional>
          <img loading="lazy" src="/email-icon.svg" alt="" />
          <Additional color="#292D37">
            <a href="mailto:contato@2ndmarket.com.br">
              contato@2ndmarket.com.br
            </a>
          </Additional>
        </BoxAdditional>
      </BoxText>
      <BoxForm onSubmit={submit}>
        {isMessageSent && (
          <Paragraph display="flex" alignItems="center">
            <Check />
            <Strong marginLeft="10px">{formLocale.validation}</Strong>
          </Paragraph>
        )}
        <BaseInput
          type="text"
          name="name"
          value={name}
          changeValue={setName}
          placeholder={formLocale.name}
        />
        <BaseInput
          type="email"
          name="email"
          value={email}
          changeValue={setEmail}
          placeholder={formLocale.email}
        />
        <BoxSelect>
          <PhoneInput
            type="phone"
            name="phone"
            value={phone}
            changeValue={setPhone}
            placeholder={formLocale.phone}
          />
          <select
            required
            name="subject"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          >
            <option value="">{formLocale.subject}</option>
            {formLocale.options.map((option, key) => (
              <option key={key} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </BoxSelect>
        <textarea
          name="message"
          value={message}
          placeholder={formLocale.message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <div className="button">
          <ButtonPattern>{formLocale.button}</ButtonPattern>
        </div>
      </BoxForm>

      <Loading message="Enviando sua mensagem..." isLoading={isLoading} />
    </Container>
  );
};

export default Block08;
