import React from "react";
import { useRouter } from "next/router";
import { ButtonPattern } from "../../components";
import { BoxContent, Container } from "./styles";

function Page404() {
  const { locale = 'pt' } = useRouter();

  const mockPage = {
      pt: {
        title: "Perdido?",
        paragraph:
          "Hum... parece que a página que você estava procurando não existe. Verifique a URL ou clique no botão abaixo para retornar a página inicial.",
        button: "Voltar para o inicio",
      },
      en: {
        title: "Lost?",
        paragraph:
          "Hum ... it seems that the page you were looking for does not exist. Check the URL or click the button below to return to the home page.",
        button: "Back to home",
      },
    }

  const pageLocale = mockPage[locale];

  return (
    <Container>
      <BoxContent>
        <img
          width="658"
          height="300"
          loading="lazy"
          alt="Imagem 404"
          src="/vector/404.svg"
          style={{ width: "100%", maxWidth: "658px" }}
        />
        <h1>{pageLocale.title}</h1>
        <p>{pageLocale.paragraph}</p>
        <ButtonPattern anchor="/">{pageLocale.button}</ButtonPattern>
      </BoxContent>
    </Container>
  );
}

export default Page404;
